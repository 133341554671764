<template>
  <div class="index_container home-container" ref="homeRef">
    <div class="home_main">
      <TopBanner></TopBanner>
      <div class="home_block">
        <div class="home_search">
          <el-input
            class="home_search_input"
            v-model="search.filter_text"
            @input="searchHandle"
            placeholder="关键词: 标题、正文、发帖人姓名"
          >
            <template #prefix>
              <el-icon><Search /></el-icon>
            </template>
          </el-input>

          <div class="home_search_title" @click="goToSearch" v-if="true">
            高级筛选
            <div class="searchInfo" v-if="isShow">
              <div class="searchIfo_item_block">
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_key">
                    <el-icon><LocationInformation /></el-icon>省份、城市
                  </div>
                </div>
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_value">
                    <el-select
                      v-model="search.province"
                      placeholder="请选择省份"
                      @change="changeProvince()"
                    >
                      <el-option
                        v-for="(item, index) in provinces"
                        :key="index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_value">
                    <el-select v-model="search.city" placeholder="请选择城市">
                      <el-option
                        v-for="(item, index) in citys"
                        :key="index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="searchIfo_item_block">
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_key">
                    <el-icon><CreditCard /></el-icon>疾病类型
                  </div>
                </div>
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_value">
                    <el-select
                      v-model="search.diseases_top_type"
                      placeholder="请选择癌种"
                      @change="changeDisease"
                    >
                      <el-option
                        v-for="(item, index) in $config.types.disease"
                        :key="index"
                        :label="index"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="searchIfo_info_row">
                  <div class="searchIfo_info_row_value">
                    <el-select
                      v-model="search.diseases"
                      placeholder="请选择疾病类型"
                    >
                      <el-option
                        v-for="(item, index) in $config.types.disease[
                          search.diseases_top_type
                        ] || []"
                        :key="index"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="searchInfo_btnGroup">
                <el-button size="small" type="success" @click.stop="doReset">
                  <span>重置</span>
                </el-button>
                <el-button size="small" type="primary" @click.stop="doSearch">
                  <span>确认筛选</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="home_search_check">
          <div class="home_search_check_title">
            <div class="home_search_title">问答列表</div>
            <el-checkbox-group
              v-model="search.role"
              size="small"
              class="home-search-role"
            >
              <el-checkbox
                @change="searchHandle"
                v-for="item in config.role.filter((role) => role.value != 2)"
                :key="item"
                :label="item.value"
                >{{ item.text }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <!-- <div class="home_search_new_host">
            <span
              @click="changeStatus('filter_hot')"
              :class="search.filter_hot ? 'global_primary_text_color' : ''"
              >最热</span
            >
            <span style="margin: 0 0.25rem">|</span>
            <span
              @click="changeStatus('filter_new')"
              :class="search.filter_new ? 'global_primary_text_color' : ''"
              >最新</span
            >
          </div> -->
        </div>
        <div class="home_list">
          <el-tag
            v-for="tag in tags"
            :key="tag.name"
            class="mx-1"
            closable
            type="danger"
            @close="handleClose(tag)"
          >
            {{ tag.name }}
          </el-tag>
          <ListScroll :url="$urls.live.all" ref="ListScroll">
            <template v-slot:listItem="{ cItems, cIndex }">
              <home-item
                class="homeItem"
                :key="cIndex"
                :item="cItems"
                :type="'home'"
              ></home-item>
            </template>
            <template v-slot:logo> <Logo></Logo> </template>
          </ListScroll>
        </div>
      </div>
    </div>
    <OutLogin v-if="$tools.isLogin()"></OutLogin>
  </div>
</template>
<script>
import ListScroll from "@/components/unit/ListScroll";
import TopBanner from "../meet/topBanner";
import Logo from "../meet/logo";
import OutLogin from "../meet/outLogin";
import { Search } from "@element-plus/icons";
import homeItem from "./homeItem.vue";
import weixin from "@/common/weixin";
import { LocationInformation, CreditCard } from "@element-plus/icons";

export default {
  name: "index",
  components: {
    Search,
    ListScroll,
    homeItem,
    TopBanner,
    OutLogin,
    Logo,
    LocationInformation,
    CreditCard,
  },
  data() {
    return {
      search: {
        filter_text: "",
        filter_role: "",
        // filter_new: 0,
        // filter_hot: 1,
        role: [],
        province: "",
        city: "",
        diseases_top_type: "",
        diseases: "",
      },
      isShow: false,
      config: {
        role: this.$config.role,
        citys: [],
      },
      timer: {
        updateTime: 0, // 更新时间
        space: 1000, // 1.5秒请求
        instance: null,
      },
    };
  },
  computed: {
    tags() {
      let result = [
        { name: this.search.province, lable: "province" },
        { name: this.search.city, lable: "city" },
        { name: this.search.diseases_top_type, lable: "diseases_top_type" },
        { name: this.search.diseases, lable: "diseases" },
      ];
      result = result.filter((v) => {
        if (v.name) return v;
      });
      return result;
    },
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.search.province) return [];
      let index = this.provinces.indexOf(this.search.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    document.getElementsByTagName("title")[0].innerHTML = "首页|骨转百讯";
    this.config.citys = await this.$tools.getCityConfig();
  },
  mounted() {},
  methods: {
    goToSearch() {
      this.isShow = !this.isShow;
    },
    doReset() {
      let data = {
        province: "",
        city: "",
        diseases_top_type: "",
        diseases: "",
      };
      this.search = { ...this.search, ...data };
      this.isShow = false;
      this.initTimer();
    },
    doSearch() {
      this.isShow = false;
      this.initTimer();
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.search[tag.lable] = "";
      this.initTimer();
    },
    changeProvince() {
      this.search.city = "";
    },
    changeDisease() {
      this.search.diseases = "";
    },
    getSearch() {
      let search = { ...this.search };
      search.filter_role = search.role.join(",");
      return search;
    },
    initTimer() {
      if (this.timer.instance) {
        clearTimeout(this.timer.instance);
        this.timer.instance = null;
      }
      if (Date.now() - this.timer.updateTime > this.timer.space) {
        this.timer.updateTime = 0;
        let search = this.getSearch();

        this.$refs.ListScroll && this.$refs.ListScroll.searchData(search);
        return;
      }
      setTimeout(() => {
        this.initTimer();
      }, 500);
    },

    searchHandle() {
      if (this.timer.updateTime > 0) {
        this.timer.updateTime = Date.now();
      } else {
        this.timer.updateTime = Date.now();
        this.initTimer();
      }
    },
    changeStatus(type) {
      if (type == "filter_new") {
        this.search.filter_new = 1;
        this.search.filter_hot = 0;
      } else {
        this.search.filter_new = 0;
        this.search.filter_hot = 1;
      }
      this.searchHandle();
    },

    outLogin() {
      weixin.wx.closeWindow();
    },
  },
};
</script>
<style>
.index_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(238, 238, 238);
}
.home_main {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.home_block {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.home_search {
  display: flex;
  padding: 0.5rem;
}
.home_search .el-input {
  flex: 1 0 0;
}
.home_search .home_search_input .el-input__wrapper {
  border-radius: 1rem;
  border: 1.5px solid #212121;
}
.home_search .el-input__prefix {
  left: 8px !important;
  top: 6px !important;
}
.home_search .el-icon {
  font-size: 20px;
  color: #212121;
}
.home_search_title {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 0.5rem;
  width: 4rem;
  position: relative;
}

.home-search-role {
  margin-left: 3rem;
  display: flex;
}
.home-search-role .el-checkbox {
  margin-right: 3rem;
}
.home_search_check {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  padding-right: 0.5rem;
}
.home_search_check .home_search_check_title {
  display: flex;
  align-items: center;
}
.home_search_check .el-checkbox__label {
  font-size: 0.9rem !important;
  padding-left: 6px !important;
}
.home_search_new_host {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}

.home_list {
  padding: 0 0.5rem;
  flex: 1 0 0;
  overflow: hidden;
}
.homeItem {
  margin-bottom: 0.5rem;
}
.homeItem:last-child {
  margin-bottom: 0;
}
.home .activation {
  color: #860241;
}

.popup_main {
  margin: 1rem 0;
  white-space: pre-line;
}
.searchInfo {
  position: absolute;
}

.searchInfo {
  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 999;
  width: 20rem;
  background: #ffffff;
  box-shadow: 0 0 0.2rem 0.2rem #cccaca;
  border-radius: 0.1rem;
  font-size: 0.8rem !important;
}
.searchIfo_item_block {
  /* -bs-bg-opacity: 1; */
  /* padding: 0.5rem; */
}
.searchIfo_info_row {
  min-height: 2.5rem;
  display: flex;
  align-items: center;
}
.searchIfo_info_row_key {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.searchIfo_info_row_key .el-icon {
  padding-right: 0.5rem;
}
.searchIfo_info_row_key .el-icon svg {
  font-size: 0.8rem;
}
.searchIfo_info_row_value {
  flex: 1 0 0;
  background: #f7f7f7;
  padding: 0 1rem;
}
.searchInfo .el-select {
  width: 100%;
  border: none;
  background: #f7f7f7;
}
.searchInfo .el-input__inner {
  border: none !important;
  background: #f7f7f7;
}
.el-input__inner::placeholder {
  font-size: 0.8rem !important;
}
.searchInfo_btnGroup {
  width: 100%;
  border: none !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.home-container .mx-1 {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.home-container .el-textarea__inner,
.home-container .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.home-container .el-select:hover:not(.el-select--disabled) .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.home-container .el-select .el-input__inner:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.home-container .el-input-group__prepend,
.home-container .el-select .el-input.is-focus .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.home_search_input .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.searchIfo_info_row_value .el-input__wrapper {
  background: #f7f7f7;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.searchIfo_info_row_value .el-input__wrapper,
.searchIfo_info_row_value .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.searchIfo_info_row_value .el-input.is-disabled .el-input__wrapper {
  background-color: #fff;
}

.searchIfo_info_row_value .el-select .el-input.is-focus .el-input__wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
